import { useParams } from "react-router";
import Footer from "../component/Footer/Footer";
import WizardFormInfomation from "../WizardFormInfomation/WizardFormInfomation";
import "./FormRegister.css";
import Header from "../component/Header";
import ToggleSmooth from "../../components/NavSearch/togglesmooth/togglesmooth";
const title = "ứng tuyển";
export default function FormRegister() {
  const params = useParams();
  console.log(params.id);
  return (
    <div>
      <div className="lg:mt-20 mt-14">
        <Header title={title} />
        <div className="text-center font-gothamBook text-[22px] sm:text-[35px] mx-4 sm:mx-0">
          Nhập thông tin ứng tuyển
        </div>
        <div className="text-center text-[#A09A9A] text-[13px] sm:text-[24px]  mx-6 sm:mx-0">
          Nhập thông tin chính xác sẽ tăng cơ hội trúng tuyển hơn
        </div>
        <div>
          <WizardFormInfomation />
        </div>
        <div className="bg-footer mt-16">
          <Footer />
        </div>
      </div>
      <ToggleSmooth />
    </div>
  );
}
