import { Helmet } from "react-helmet";
import TITLE_IMG from "../../assets/image/img-title.jpg";
import parse from "html-react-parser";
import { MetaTags } from "react-meta-tags";
export default function ContentPage({ jobDetail }) {
  console.log(jobDetail);
  return (
    <>
      {/* <Helmet>
        <meta charSet="utf-8" />
        <title>{jobDetail?.data?.job_position}</title>
        <meta name="description" content={jobDetail?.data?.meta_description} />
        <script type="application/ld+json">{`
        {
            "@context": "http://schema.org",
            "@type":"Course",
            "name": ${jobDetail?.data?.meta_description},
            "description": ${jobDetail?.data?.meta_description},
        }
    `}</script>
        <meta
          property="og:description"
          content={jobDetail?.data?.meta_description}
        />
      </Helmet> */}
      <MetaTags>
        <title>{jobDetail?.data?.job_position}</title>
        <meta name="description" content={jobDetail?.data?.meta_description} />
        <meta property="og:title" content="MyApp" />
        <meta property="og:image" content="path/to/image.jpg" />
        <meta
          property="og:description"
          content={jobDetail?.data?.meta_description}
        />
      </MetaTags>
      <div className="col-span-1 lg:col-span-4 ">
        <div className="flex justify-center  ">
          <img
            src={jobDetail?.data?.banner_detail_url}
            className="rounded-lg w-11/12 max-w-[600px] "
          />
        </div>

        <div className="pl-6 mt-7 font-gothamRegular title-header">
          <div>MÔ TẢ CÔNG VIỆC</div>
          <div
            dangerouslySetInnerHTML={{
              __html: jobDetail?.data?.job_description,
            }}
            className="ca font-gothamBook description-sale mt-2 px-5 div-ne"
          ></div>
        </div>
        <div className="pl-6 mt-7 font-gothamRegular title-header">
          <div>YÊU CẦU CÔNG VIỆC</div>
          <div
            dangerouslySetInnerHTML={{
              __html: jobDetail?.data?.job_requirement,
            }}
            className="ca font-gothamBook description-sale mt-2 px-5 div-ne"
          ></div>
        </div>
        <div className="pl-6 mt-7 font-gothamRegular title-header">
          <div>QUYỀN LỢI</div>
          <div
            dangerouslySetInnerHTML={{
              __html: jobDetail?.data?.benefit,
            }}
            className="ca font-gothamBook description-sale mt-2 px-5 div-ne"
          ></div>
        </div>
        <div className="pl-6 mt-7 font-gothamRegular title-header">
          <div>THỜI GIAN LÀM VIỆC</div>

          <div
            className="ca font-gothamBook description-sale mt-2 px-5 list-disc div-ne"
            dangerouslySetInnerHTML={{
              __html: jobDetail?.data?.working_time,
            }}
          ></div>
        </div>
        <div className="pl-6 mt-7 font-gothamRegular title-header">
          <div>LIÊN HỆ</div>
          <div
            className="ca font-gothamBook description-sale mt-2 px-5 list-disc div-ne"
            dangerouslySetInnerHTML={{
              __html: jobDetail?.data?.contact,
            }}
          ></div>
        </div>
      </div>
    </>
  );
}
