import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./PageMain.css";
import ListJobOfficial from "../ListJobOfficial/ListJobOfficial";
import WORK from "../../assets/image/work.png";
import ListJobSession from "../ListJobSession/ListJobSession";
import DN from "../../assets/image/dn.png";
import PP from "../../assets/image/pp.png";
import GL from "../../assets/image/gl.png";
import HS from "../../assets/image/hs.png";
import Footer from "../component/Footer/Footer";
import { useEffect, useState } from "react";
import { jobServices } from "../services/apiService/jobServices";
import { useQuery } from "react-query";
import Header from "../component/Header/HeaderNavBar";
import NavSearch from "../../components/NavSearch/NavSearch";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import ToggleSmooth from "../../components/NavSearch/togglesmooth/togglesmooth";
import ChartBenefits from "./ChartBenefits/ChartBenefits";
import NewsMainList from "./NewsMainList/NewsMainList";
import { MetaTags } from "react-meta-tags";

const test = [{ img: DN }, { img: GL }, { img: PP }, { img: HS }];
let settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
  ],
};
export default function PageMain() {
  const [modalIsOpen, setIsOpen] = useState(false);
  const urlJobOfficial = `?page=1&limit=3&job_type_id=1`;
  const urlJobSession = `?page=1&limit=3&job_type_id=${2}`;
  const navigate = useNavigate();
  const getPermission = async () => {
    const permission = await navigator?.permissions?.query({
      name: "camera",
    });
    console.log(permission?.state);
  };

  useEffect(() => {
    getPermission();
  }, []);
  const dataOfficial = useQuery({
    queryKey: ["getJobOfficial"],
    queryFn: () => jobServices.getJob(urlJobOfficial),
  });

  const dataSession = useQuery({
    queryKey: ["getJobSession"],
    queryFn: () => jobServices.getJob(urlJobSession),
  });
  console.log(dataSession);
  const typeJob = useQuery({
    queryKey: ["getTypeJob"],
    queryFn: () => jobServices.getJobType(),
  });
  console.log(typeJob);
  const banner = useQuery({
    queryKey: ["getbanner"],
    queryFn: () => jobServices.getBanner(),
  });
  console.log(banner);

  return (
    <>
      <MetaTags>
        <title>MVC PRO</title>
      </MetaTags>
      <Header setIsOpen={setIsOpen} />
      <div className="mt-12">
        <div className="relative top-0 ">
          <Carousel
            autoPlay
            centerMode={false}
            showArrows={false}
            infiniteLoop={true}
            transitionTime={1500}
            showThumbs={false}
            showStatus={false}
            showIndicators={true}
            preventMovementUntilSwipeScrollTolerance={false}
            className=""
          >
            {banner?.data?.data?.map((res, index) => {
              // console.log(res);
              // console.log(index);

              return (
                <div key={index}>
                  <img src={res} className="w-full" />
                </div>
              );
            })}
          </Carousel>

          <NavSearch />
        </div>
        <div className="w-[90%] sm:w-[65%] min-[1024px]:w-[90%] min-[1700px]:w-[55%] min-[1300px]:w-[73%] relative content-main">
          <div className="flex">
            <div className="mr-2 flex items-center">
              <img src={WORK} className="w-5" />
            </div>
            <div className="font-regular text-[#3AA3A4] text-[24px]">
              GỢI Ý CÔNG VIỆC
            </div>
          </div>
          <ListJobOfficial dataOfficial={dataOfficial} />
        </div>

        <div className="w-[90%] sm:w-[65%] min-[1024px]:w-[90%] min-[1700px]:w-[55%] min-[1300px]:w-[73%] relative content-main">
          <ListJobSession dataSession={dataSession} />
        </div>

        <ChartBenefits />
        <NewsMainList />
        <div className="min-[1700px]:w-[55%] w-[90%] sm:w-[80%] lg:w-[70%] relative content-main mt-8">
          <img src={WORK} className="w-6 relative left-1/2 -translate-x-1/2" />
          <div className="text-center font-regular text-[#3AA3A4] text-[24px]">
            NHÃN HÀNG ĐỐI TÁC
          </div>
          <div className="mt-10">
            <Slider {...settings}>
              {test?.map((res) => (
                <>
                  <div className="max-w-[350px] w-[150px] content-main h-[80px]  relative flex justify-center ">
                    <div className="max-h-[60px] h-[60px]">
                      <img
                        src={res.img}
                        id="img-slick"
                        className="left-1/2 -translate-x-1/2 rounded-xl h-[40px] shadow-img relative top-1 flex items-center justify-center w-full "
                      />
                    </div>
                  </div>
                </>
              ))}
            </Slider>
          </div>
        </div>
        <div className="bg-footer mt-16">
          <Footer />
        </div>
      </div>
      <ToggleSmooth />
    </>
  );
}
