import FOOTER_MVC from "../../../assets/image/logofooter_mvc.png";
import MAIL from "../../../assets/image/mail.png";
import CALL from "../../../assets/image/call.png";
import ZALO from "../../../assets/image/zalo-logo.png";
import FACE from "../../../assets/image/face-logo.png";

import DROP_DOWN from "../../../assets/image/arrow_down.svg";
import "../../FormRegister/FormRegister.css";

const EMAIL = "tuyendung@mvcpro.vn";
const PHONE = "(028)35170278";

export default function Footer() {
  const handleEmail = (e) => {
    e.preventDefault();
    window.location.href = `mailto:${EMAIL}`;
  };
  const handlePhone = (e) => {
    e.preventDefault();
    window.location.href = `phone:${PHONE}`;
  };

  const handleRedirect = (redirect) => {
    window.open(redirect, "_blank");
  };

  return (
    <div className="p-3 sm:p-0 relative sm:w-[80%] lg:w-[73%] min-[1700px]:w-[55%] content-main sm:pt-12 pt-5 mt-20  mx-0 grid md:grid-cols-3 grid-cols-1 gap-5">
      <div className="col-span-1 content-center sm:mt-10 mt-0">
        <div className="font-regular text-white text-[22px]">
          THÔNG TIN LIÊN HỆ
        </div>
        <div className="mt-4">
          <img src={FOOTER_MVC} className="w-64" />
        </div>
        <div className="mt-3 flex items-center">
          <img src={MAIL} className="w-4 h-4" />
          <div
            onClick={handleEmail}
            className="text-[17px] ml-3 w-full text-[#C9D5D4]"
          >
            <a href="mailto:tuyendung@mvcpro.vn">tuyendung@mvcpro.vn</a>
          </div>
        </div>
        <div className="mt-3 flex">
          <img src={CALL} className="w-4 h-4" />
          <div className="ml-3 w-full text-[#C9D5D4]">
            <a href="tel:+02835170278">(028) 3517 0278</a>
          </div>
        </div>

        <div className="mt-3 flex">
          <img
            src={FACE}
            className="w-12 h-12 mr-5 hover:cursor-pointer"
            onClick={() => handleRedirect("https://www.facebook.com/mvcpro.vn")}
          />
          <img
            src={ZALO}
            className="w-12 h-12 hover:cursor-pointer"
            onClick={() =>
              handleRedirect("https://zalo.me/2069335117770581954")
            }
          />
        </div>
      </div>
      <div className="sm:mt-10 mt-3 font-gothamRegular text-[22px] col-span-1 text-white">
        <div className="font-regular text-white text-[22px]">
          ĐỊA CHỈ VĂN PHÒNG
        </div>
        <div className="text-[15px] mt-2 font-book">
          Trụ sở chính:
          <div className="text-[13px] text-[#DBDBD6]">
            48 Hoa Mai, Phường 2, Phú Nhuận, Thành phố Hồ Chí Minh
          </div>
        </div>

        <div className="dropdown">
          <div className="text-[15px] mt-2 flex dropbtn font-book">
            Văn phòng tại Hà Nội
            <img src={DROP_DOWN} className="ml-2" />
          </div>
          <div className="text-[13px] mt-1 text-[#DBDBD6] dropdown-ani">
            50, Ngõ 168 đường Phan Trọng Tuệ, xã Thanh Liệt,. huyện Thanh Trì,
            TP. Hà Nội.
          </div>
        </div>
        <div className="dropdown">
          <div className="text-[15px] mt-2 flex dropbtn font-book">
            Văn phòng tại Đà Nẵng
            <img src={DROP_DOWN} className="ml-2" />
          </div>
          <div className="text-[13px] mt-1 text-[#DBDBD6] dropdown-ani">
            21 Bãi Sậy, Phường Khuê Trung, Quận Cẩm Lệ, TP. Đà Nẵng​.
          </div>
        </div>
      </div>
      <div className="sm:mt-10 mt-3 sm:col-span-1 font-gothamRegular text-white text-[22px]">
        <div className="dropdown-content font-regular text-white text-[22px]">
          BẢN ĐỒ CÔNG TY
        </div>
        <div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1959.588471150336!2d106.68612754413057!3d10.797756311729952!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x317528d1b425d9bf%3A0x3dfc4512241cdf8b!2zNDggSG9hIE1haSwgUGjGsOG7nW5nIDIsIFBow7ogTmh14bqtbiwgVGjDoG5oIHBo4buRIEjhu5MgQ2jDrSBNaW5oLCBWaeG7h3QgTmFt!5e0!3m2!1svi!2s!4v1665637191042!5m2!1svi!2s"
            height="300"
            style={{
              marginTop: "20px",
              border: 0,
              width: "90%",
              borderRadius: "10px",
              marginBottom: "50px",
            }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
}
