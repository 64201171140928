import { axiosInstance, configHeader } from "./configURL";

export const jobServices = {
  getContact: (data) => {
    return axiosInstance.post(
      "/recruitment/contact",
      { params: data },
      configHeader
    );
  },
  getJob: (url) => {
    return axiosInstance.get("/recruitment/job_position" + url, configHeader);
  },
  getJobType: () => {
    return axiosInstance.get("/recruitment/job_type", configHeader);
  },

  getBanner: () => {
    return axiosInstance.get("/recruitment/banner", configHeader);
  },
  getJobDetails: (id) => {
    return axiosInstance.get("/recruitment/job_position/" + id);
  },
  checkJob: (data) => {
    return axiosInstance.post(
      "/recruitment/result",
      { params: data },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },
  applyJob: (data) => {
    return axiosInstance.post(
      "/recruitment/apply_cv",
      { params: data },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },
};
