import moment from "moment";

export const formatDate = (date = "") => {
  return moment(date).format("DD/MM/YYYY");
};

export const formatMoney = (x) => {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return parts.join(".");
};
