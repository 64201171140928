import { useEffect, useState } from "react";

export default function Two(props) {
  const dataLocal = JSON.parse(localStorage.getItem("Data_Object") || "{}");
  console.log(dataLocal);

  // console.log(props);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [exCheck, setExCheck] = useState(true);
  const [experience, setExperience] = useState("");

  const [exNumberCheck, setNumberExCheck] = useState("");

  const handleCheckExperience = (e) => {
    setExCheck(true);
    setExperience("1 năm");
  };
  const handleCheckNoExperience = (e) => {
    setExCheck(false);
    setExperience("no");
  };
  const handleNumberCheckExperience = (e) => {
    console.log(e.target.value);
    setExperience(e.target.value);
  };

  const validate2 = () => {
    const data = {
      yoe: parseInt(experience),

      experienced: exCheck,
    };
    console.log(data);
    if (data.yoe < 0) {
      setNumberExCheck("Nhập số năm lớn hơn 0");
    } else {
      const result = Object.assign(dataLocal, data);
      console.log(result);
      localStorage.setItem("Data_Object", JSON.stringify(result));
      props.nextStep();
    }
  };
  const handleBack = () => {
    props.previousStep();
  };

  // console.log(exCheck);
  return (
    <div>
      <div className="mx-14 sm:mx-32">
        <form>
          <p>Kinh nghiệm làm việc:</p>
          <input
            type="radio"
            id="html"
            name="fav_language"
            value="yes"
            className="mt-2"
            defaultChecked
            onClick={handleCheckExperience}
          />
            <label htmlFor="html">Có kinh nghiệm</label>
          <br></br>
          <input
            type="radio"
            id="html1"
            name="fav_language"
            value="no"
            onClick={handleCheckNoExperience}
          />
            <label htmlFor="html1">Chưa có kinh nghiệm</label>
          <br></br>
        </form>
        {exCheck === false ? (
          <form>
            <div className="mt-5">
              <p>Số năm kinh nghiệm ở vị trí tương đương:</p>
              <input
                type="number"
                value={experience}
                placeholder="Nhập số năm kinh nghiệm"
                className="mt-2 h-10 border-2 pl-2 w-8/12"
                onChange={(e) => handleNumberCheckExperience(e)}
              />
               <br></br>
            </div>
            <div className="text-red-600">{exNumberCheck}</div>
          </form>
        ) : null}
      </div>
      <div className="flex justify-around mt-10">
        <div
          className="hover:cursor-pointer w-36 h-9 btn-enable -mr-5 flex justify-center items-center cursor-pointer"
          onClick={handleBack}
        >
          Quay lại
        </div>
        <div
          className="hover:cursor-pointer w-36 h-9 btn-enable flex justify-center items-center cursor-pointer"
          onClick={validate2}
        >
          Tiếp theo
        </div>
      </div>
    </div>
  );
}
