import BD from "../../../assets/image/bd1.jpg";
import BD2 from "../../../assets/image/bd2.jpg";
import BD3 from "../../../assets/image/bd3.jpg";
import WORK from "../../../assets/image/work.png";
import TT from "../../../assets/image/tt.png";
import TT1 from "../../../assets/image/tt1.png";
import { useNavigate } from "react-router-dom";
export default function NewsMainList() {
  const navigate = useNavigate();
  const handleNewDetail = () => {
    navigate("/news-detail");
  };
  return (
    <div className="w-[90%] min-[1700px]:w-[55%] sm:w-[65%] min-[1024px]:w-[90%] min-[1300px]:w-[73%] relative content-main my-8">
      <div className="flex">
        <div className="mr-2 flex items-center">
          <img src={WORK} className="w-5" />
        </div>
        <div className=" font-regular text-[#3AA3A4] text-[24px]">
          CÁC TIN TỨC MỚI NHẤT
        </div>
      </div>
      <div className="mt-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        <div
          className="hover:cursor-pointer col-span-1 p-2 shadow-grid rounded-lg border-ani"
          onClick={handleNewDetail}
        >
          <img
            src={TT}
            className="object-cover w-full rounded-md repon-img max-h-[230px]"
          />
          <div className="text-[15px] title-reponsive mt-2 font-regular">
            MVCPRO tổ chức chương trình huấn luyện cho Nhân viên bán hàng
          </div>
          <p className="text-[14px] text-reponsive mt-2 font-book">
            Những Nữ chiến binh bán hàng của MVCPRO đã có những trải nghiệm
            tuyệt vời tại buổi Training Kỹ Năng đọc vị tâm lý khách hàng và chốt
            sale...
          </p>
          <div className="hover:cursor-pointer text-[13px] mt-2 font-book text-[#3AA3A4]">
            Xem thêm {">"}
          </div>
        </div>

        <div
          className="border-ani col-span-1 p-2 shadow-grid rounded-lg hover:cursor-pointer"
          onClick={handleNewDetail}
        >
          <img
            src={TT1}
            className="rounded-md w-full repon-img max-h-[230px]"
          />
          <div className="text-[15px] title-reponsive mt-2 font-regular">
            MVCPRO tổ chức chương trình huấn luyện cho Nhân viên bán hàng
          </div>
          <p className="text-[14px] text-reponsive mt-2 font-book">
            Những Nữ chiến binh bán hàng của MVCPRO đã có những trải nghiệm
            tuyệt vời tại buổi Training Kỹ Năng đọc vị tâm lý khách hàng và chốt
            sale...
          </p>
          <div className="hover:cursor-pointer text-[13px] mt-2 font-book text-[#3AA3A4]">
            Xem thêm {">"}
          </div>
        </div>
        <div className="min-[1024px]:col-span-1  min-[1024px]:grid min-[1024px]:grid-cols-1 min-[1024px]:gap-6 max-[1024px]:hidden">
          {/* <div className=""> */}
          <div
            onClick={handleNewDetail}
            className="border-ani col-span-1 shadow-grid mb-2 rounded-lg hover:cursor-pointer"
          >
            <div className="p-2 flex">
              <div className="flex items-center">
                <img src={BD2} className=" w-60 max-h-[180px] rounded-md" />
              </div>
              <div className="px-2 flex flex-col justify-around">
                <div className="text-[15px] font-regular">
                  MVC PRO gắn kết nhân viên thông qua hoạt động TeamBuilding đầy
                  hứng ...
                </div>
                <div className="text-[13px] font-book text-[#13a5a5]">
                  Xem thêm {">"}
                </div>
              </div>
            </div>
          </div>
          <div
            onClick={handleNewDetail}
            className="border-ani col-span-1 shadow-grid mb-2 rounded-lg hover:cursor-pointer"
          >
            <div className="p-2 flex">
              <div className="flex items-center">
                <img src={BD3} className="w-60 max-h-[180px] rounded-md" />
              </div>
              <div className="px-2 flex flex-col justify-around">
                <div className="text-[15px] font-regular">
                  MVC PRO gắn kết nhân viên thông qua hoạt động TeamBuilding đầy
                  hứng ...
                </div>
                <div className="text-[13px] font-book text-[#13a5a5]">
                  Xem thêm {">"}
                </div>
              </div>
            </div>
          </div>

          <div
            onClick={handleNewDetail}
            className="border-ani col-span-1 shadow-grid mb-2 rounded-lg hover:cursor-pointer"
          >
            <div className="p-2 flex">
              <div className="flex items-center">
                <img src={BD} className="w-60 rounded-md" />
              </div>
              <div className="px-2 flex flex-col justify-around">
                <div className="text-[15px] font-regular">
                  MVC PRO gắn kết nhân viên thông qua hoạt động TeamBuilding đầy
                  hứng ...
                </div>
                <div className="text-[13px] font-book text-[#13a5a5]">
                  Xem thêm {">"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 grid grid-cols-1 gap-8 min-[1024px]:hidden">
        <div className="p-2 flex shadow-grid rounded-xl border-ani">
          <div className="flex items-center">
            <img src={BD} className="h-28 w-[260px] rounded-lg" />
          </div>
          <div className="px-2 flex flex-col justify-around">
            <div className="text-[15px] font-regular">
              MVC PRO gắn kết nhân viên thông qua hoạt động TeamBuilding đầy
              hứng ...
            </div>
            <div className="text-[13px] font-book text-[#13a5a5]">
              Xem thêm {">"}
            </div>
          </div>
        </div>
        <div className="p-2 flex shadow-grid rounded-xl border-ani">
          <div className="flex items-center">
            <img src={BD3} className="h-28 w-[260px] rounded-lg" />
          </div>
          <div className="px-2 flex flex-col justify-around">
            <div className="text-[15px] font-regular">
              MVC PRO gắn kết nhân viên thông qua hoạt động TeamBuilding đầy
              hứng ...
            </div>
            <div className="text-[13px] font-book text-[#13a5a5]">
              Xem thêm {">"}
            </div>
          </div>
        </div>
        <div className="p-2 flex shadow-grid rounded-xl border-ani">
          <div className="flex items-center">
            <img src={BD2} className="h-28 w-[260px] rounded-lg" />
          </div>
          <div className="px-2 flex flex-col justify-around">
            <div className="text-[15px] font-regular">
              MVC PRO gắn kết nhân viên thông qua hoạt động TeamBuilding đầy
              hứng ...
            </div>
            <div className="text-[13px] font-book text-[#13a5a5]">
              Xem thêm {">"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
