import { useQuery } from "react-query";
import { jobServices } from "../services/apiService/jobServices";
import Header from "../component/Header/HeaderNavBar";
import { Carousel } from "react-responsive-carousel";
import ItemListSearch from "../ContentRecruitment/ItemListSearch";
import NavSearch from "../../components/NavSearch/NavSearch";
import WORK from "../../assets/image/work.png";
import FACE from "../../assets/image/facebook-share.png";
import { useEffect, useState } from "react";
import Footer from "../component/Footer/Footer";
import { Pagination } from "antd";
import { useParams } from "react-router-dom";
import ToggleSmooth from "../../components/NavSearch/togglesmooth/togglesmooth";
import { MetaTags } from "react-meta-tags";
export default function ListJob() {
  const { id } = useParams();
  console.log(id);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [job, setJob] = useState();

  const urlJobOfficial = `?job_type_id=${id}&page=1`;
  const dataOfficial = useQuery({
    queryKey: ["getJobOfficial"],
    queryFn: () => jobServices.getJob(urlJobOfficial),
  });

  const banner = useQuery({
    queryKey: ["getbanner"],
    queryFn: () => jobServices.getBanner(),
  });
  console.log(banner);
  const [current, setCurrent] = useState(id);
  useEffect(() => {
    window.scrollTo(0, 0);
    jobServices
      .getJob(urlJobOfficial)
      .then((res) => {
        console.log(res);
        setJob(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const hanldeClick = (e, status_gifts) => {
    setNumber(1);
    setCurrent(e.target.id);
    jobServices
      .getJob(`?job_type_id=` + e.target.id)
      .then((res) => {
        setJob(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [number, setNumber] = useState(1);
  const handleD = (data) => {
    window.scrollTo(0, 0);
    console.log(data);
    setNumber(data);
    jobServices
      .getJob(`?job_type_id=${current}&page=${data}`)
      .then((res) => {
        console.log(res);
        setJob(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <MetaTags>
        <title>Cơ hội nghề nghiệp</title>
      </MetaTags>
      <Header setIsOpen={setIsOpen} />
      <div className="mt-12">
        <div className="relative top-0">
          <Carousel
            autoPlay
            centerMode={false}
            showArrows={false}
            infiniteLoop={true}
            transitionTime={1500}
            showThumbs={false}
            showStatus={false}
            showIndicators={true}
            preventMovementUntilSwipeScrollTolerance={false}
            className=""
          >
            {banner?.data?.data?.map((res, index) => {
              // console.log(res);
              // console.log(index);

              return (
                <div key={index}>
                  <img src={res} className="w-full" />
                </div>
              );
            })}
          </Carousel>

          <NavSearch />
        </div>
      </div>
      <div className="mt-0 flex flex-col justify-center items-center">
        <div className="bg-tab tab flex justify-between items-center align-middle w-full max-w-[400px] mb-5 bg-tab">
          <div className="flex justify-center flex-auto">
            <button
              key="1"
              id={1}
              disabled={current === `${1}`}
              onClick={(e) => hanldeClick(e, "done")}
              className="font-book  text-[15px]"
            >
              Việc làm chính thức
            </button>
          </div>
          <div className="flex justify-center flex-auto">
            <button
              key="2"
              id={2}
              disabled={current === `${2}`}
              onClick={(e) => hanldeClick(e, "not_yet")}
              className="font-book  text-[15px]"
            >
              Việc làm thời vụ
            </button>
          </div>
        </div>
      </div>
      <div className="w-[90%] sm:w-[65%] min-[1024px]:w-[90%] min-[1300px]:w-[73%] min-[1700px]:w-[55%] relative content-main grid grid-cols-6 gap-5">
        <div className="lg:col-span-4 col-span-6">
          {/* <div> */}
          {job?.data?.map((data) => (
            // <div key={data?.job_id}>
            <ItemListSearch data={data} />
            // </div>
          ))}
          {/* </div> */}
          <div className="flex justify-center mt-2">
            <Pagination
              defaultCurrent={1}
              total={job?.total_job}
              onChange={handleD}
              current={number}
              responsive={true}
            />
          </div>
        </div>
        <div className="lg:col-span-2 col-span-6">
          <div className="mt-2">
            <iframe
              src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fmvcpro.vn%2F%3Fref%3Dembed_page&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=614506609862199"
              width="340"
              height="300"
              scrolling="no"
              frameborder="0"
              allowfullscreen="true"
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
            ></iframe>
          </div>
        </div>
      </div>
      <div className="bg-footer mt-16">
        <Footer />
      </div>
      <ToggleSmooth />
    </>
  );
}
