import POP1 from "../../assets/image/pop1.png";
import POP2 from "../../assets/image/pop2.png";
import POP3 from "../../assets/image/pop3.png";
import POP4 from "../../assets/image/pop4.png";
import POP5 from "../../assets/image/pop5.png";

export default function PopupInfor({ index, set_open_popup }) {
  const data = [
    {
      img: POP1,
      title: "LƯƠNG THƯỞNG HẤP DẪN",
      content:
        "Lương cạnh tranh, thưởng hấp dẫn tùy theo vị trí công việc và thưởng thành tích hàng tháng, hàng quý và theo từng dự án",
    },
    {
      img: POP2,
      title: "PHÚC LỢI ĐẦY ĐỦ",
      content:
        "Được tham gia đầy đủ các chính sách phúc lợi theo luật quy định (bảo hiểm xã hội, bảo hiểm sức khỏe, chương trình khám sức khỏe tổng quát định kỳ theo quy định)​",
    },
    {
      img: POP3,
      title: "LỘ TRÌNH THĂNG TIẾN",
      content:
        "Lộ trình thăng tiến rõ ràng, được xét tăng lương, thăng tiến hàng năm​",
    },
    {
      img: POP4,
      title: "ĐÀO TẠO BÀI BẢN",
      content:
        "Được đào tạo kiến thức và kĩ năng chuyên môn bởi các chuyên gia dày dặn kinh nghiệm",
    },
    {
      img: POP5,
      title: "HOẠT ĐỘNG NGOẠI KHÓA",
      content:
        "Được tham gia các chương trình team building, du lịch,.. ​ của công ty hàng năm",
    },
  ];
  return (
    <div className="fixed z-10 overflow-y-auto top-0 w-full left-0 " id="modal">
      <div className="flex items-center justify-center min-height-100vh px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity">
          <div
            className="absolute inset-0 bg-gray-900 opacity-70"
            onClick={() => set_open_popup(false)}
          />
          <div className="content-popup-condition">
            <div className=" content-popupgame p-3 rounded-sm">
              <div className="font-regular text-[#13a5a5] py-3">
                {data[index].title}
              </div>
              <div>
                <img src={data[index].img} className="rounded-lg" />
              </div>
              <div className="text-[13px] font-book py-3">
                {data[index].content}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
