import PHONE from "../../assets/image/phone-z.png";
import WORK from "../../assets/image/work.png";
import DN from "../../assets/image/dn.png";
import PP from "../../assets/image/pp.png";
import GL from "../../assets/image/gl.png";
import HS from "../../assets/image/hs.png";
import Slider from "react-slick";
import Footer from "../component/Footer/Footer";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { jobServices } from "../services/apiService/jobServices";
import { useState } from "react";
import ToggleSmooth from "../../components/NavSearch/togglesmooth/togglesmooth";

const test = [{ img: DN }, { img: GL }, { img: PP }, { img: HS }];
let settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export default function Tracuu() {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    criteriaMode: "all",
  });
  const [isCheck, setIsCheck] = useState(false);
  const [dataCheck, setDataCheck] = useState(false);

  const onSubmit = (data) => {
    console.log(data);
    jobServices
      .checkJob(data)
      .then((res) => {
        setIsCheck(true);

        console.log(res);
        setDataCheck(res.data.result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="mt-28 w-[90%] sm:w-[80%] min-[1024px]:w-[90%] min-[1300px]:w-[73%] relative content-main">
        <div className="shadow-grid p-5 rounded-lg top-10 relative left-1/2 -translate-x-1/2 max-w-[450px]">
          <div className="font-regular text-[18px] text-[#13a5a5] text-center">
            Tra cứu kết quả ứng tuyển
          </div>
          <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex mt-10 items-center">
              <label>
                <img src={PHONE} />
              </label>
              <input
                type="tel"
                className=" w-full h-10 mr-12 ml-5 pl-5 form-control border-b-[1px]"
                placeholder="Số điện thoại"
                {...register("phone", {
                  required: "Không được để trống",
                  pattern: {
                    value: /(84|0[3|5|7|8|9])+([0-9]{8})\b/,
                    message: "Vui lòng nhập số",
                  },
                })}
              />
            </div>
            <div className="font-normal z-0  text-[red] text-[13px] text-center">
              <ErrorMessage
                errors={errors}
                name="phone"
                render={({ messages }) => {
                  console.log("messages", messages);
                  return messages
                    ? Object.entries(messages).map(([type, message]) => (
                        <p
                          key={type}
                          className=" pt-2.5 z-0 rounded-bl-xl rounded-br-xl "
                        >
                          {message}
                        </p>
                      ))
                    : null;
                }}
              />
            </div>
            <div className="flex justify-center">
              <button
                type="submit"
                className="mt-14 mb-5  btn-ut bg-[#3AA3A4] text-[15px] px-6 py-2 text-white rounded-3xl"
              >
                Tra cứu
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="w-[90%] sm:w-[80%] lg:w-[70%] relative content-main mt-20">
        <img src={WORK} className="w-6 relative left-1/2 -translate-x-1/2" />
        <div className="text-center font-regular text-[#3AA3A4] text-[24px]">
          NHÃN HÀNG ĐỐI TÁC
        </div>
        <div className="mt-10">
          <Slider {...settings}>
            {test?.map((res) => (
              <>
                <div className="max-w-[350px] w-[150px] content-main h-[80px] relative mx-5">
                  <div className="max-h-[60px] h-[60px]">
                    <img
                      src={res.img}
                      id="img-slick"
                      className="rounded-xl h-[40px] shadow-img relative top-1 flex items-center justify-center w-full "
                    />
                  </div>
                </div>
              </>
            ))}
          </Slider>
        </div>
      </div>
      <div className="bg-footer mt-16">
        <Footer />
      </div>
      <ToggleSmooth />
    </>
  );
}
