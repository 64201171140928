import { useEffect, useState } from "react";
import ContentPage from "../ContentRecruitment/ContentPage";
import PopupContent from "../ContentRecruitment/PopupContent";
import Header from "../component/Header";
import TT from "../../assets/image/tt.png";
import TT1 from "../../assets/image/tt1.png";
import TT2 from "../../assets/image/tt2.png";
import NEW1 from "../../assets/image/news-1.png";

import Footer from "../component/Footer/Footer";
import ToggleSmooth from "../../components/NavSearch/togglesmooth/togglesmooth";
import { MetaTags } from "react-meta-tags";
let title = "TIN TỨC - SỰ KIỆN";
export default function News() {
  const [jobDetail, setJobDetail] = useState([]);
  const [current, setCurrent] = useState("1");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const hanldeClick = (e, status_gifts) => {
    setCurrent(e.target.id);
  };
  return (
    <div className="lg:mt-20 mt-16">
      <MetaTags>
        <title>Tin tức - Sự kiện</title>
      </MetaTags>
      <Header title={title} />
      <div className="mt-10 flex flex-col justify-center items-center px-5">
        <div className="bg-tab tab flex justify-between items-center align-middle w-full max-w-[600px] mx-5 mb-5 bg-tab">
          <div className="flex justify-center flex-auto">
            <button
              key="1"
              id={1}
              disabled={current === `${1}`}
              onClick={(e) => hanldeClick(e, "done")}
              className="font-book  text-[13px]"
            >
              Tin tức mới
            </button>
          </div>
          <div className="flex justify-center flex-auto">
            <button
              key="2"
              id={2}
              disabled={current === `${2}`}
              onClick={(e) => hanldeClick(e, "not_yet")}
              className="font-book  text-[13px]"
            >
              Cuộc sống MVC
            </button>
          </div>
          <div className="flex justify-center flex-auto">
            <button
              key="3"
              id={3}
              disabled={current === `${3}`}
              onClick={(e) => hanldeClick(e, "not_yet")}
              className="font-book  text-[13px]"
            >
              Cẩm ngang nghề nghiệp
            </button>
          </div>
        </div>
      </div>
      <div className="w-[90%] sm:w-[95%] min-[1024px]:w-[95%] min-[1300px]:w-[100%] relative content-main my-0">
        <div className="lg:mx-20 2xl:mx-56 sm:mx-20 ">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 mt-5">
            <div className="col-span-1 p-2 shadow-grid rounded-lg">
              <img src={TT} className="rounded-md" />
              <div className="text-[16px] text-reponsive mt-2 font-regular">
                MVCPRO tổ chức chương trình huấn luyện cho Nhân viên bán hàng
              </div>
              <p className="text-[14px] text-reponsive mt-2 font-book">
                Những Nữ chiến binh bán hàng của MVCPRO đã có những trải nghiệm
                tuyệt vời tại buổi Training Kỹ Năng đọc vị tâm lý khách hàng và
                chốt sale...
              </p>
              <div className="hover:cursor-pointer text-[13px] mt-2 font-book text-[#3AA3A4]">
                Xem thêm {">"}
              </div>
            </div>
            <div className="col-span-1 p-2 shadow-grid rounded-lg">
              <img src={TT1} className="rounded-md" />
              <div className="text-[15px] text-reponsive mt-2 font-regular">
                MVCPRO tổ chức chương trình huấn luyện cho Nhân viên bán hàng
              </div>
              <p className="text-[14px] text-reponsive mt-2 font-book">
                Những Nữ chiến binh bán hàng của MVCPRO đã có những trải nghiệm
                tuyệt vời tại buổi Training Kỹ Năng đọc vị tâm lý khách hàng và
                chốt sale...
              </p>
              <div className="hover:cursor-pointer text-[13px] mt-2 font-book text-[#3AA3A4]">
                Xem thêm {">"}
              </div>
            </div>

            <div className="col-span-1 p-2 shadow-grid rounded-lg">
              <img src={TT} className="rounded-md" />
              <div className="text-[15px] text-reponsive mt-2 font-regular">
                MVCPRO tổ chức chương trình huấn luyện cho Nhân viên bán hàng
              </div>
              <p className="text-[14px] text-reponsive mt-2 font-book">
                Những Nữ chiến binh bán hàng của MVCPRO đã có những trải nghiệm
                tuyệt vời tại buổi Training Kỹ Năng đọc vị tâm lý khách hàng và
                chốt sale...
              </p>
              <div className="hover:cursor-pointer text-[13px] mt-2 font-book text-[#3AA3A4]">
                Xem thêm {">"}
              </div>
            </div>
            <div className="col-span-1 p-2 shadow-grid rounded-lg">
              <img src={NEW1} className="rounded-md" />
              <div className="text-[15px] text-reponsive mt-2 font-regular">
                MVCPRO tổ chức chương trình huấn luyện cho Nhân viên bán hàng
              </div>
              <p className="text-[14px] text-reponsive mt-2 font-book">
                Những Nữ chiến binh bán hàng của MVCPRO đã có những trải nghiệm
                tuyệt vời tại buổi Training Kỹ Năng đọc vị tâm lý khách hàng và
                chốt sale...
              </p>
              <div className="hover:cursor-pointer text-[13px] mt-2 font-book text-[#3AA3A4]">
                Xem thêm {">"}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-footer mt-16">
        <Footer />
      </div>
      <ToggleSmooth />
    </div>
  );
}
