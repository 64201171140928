import JOB_OFFICIAL from "../../assets/image/job-official.png";
import JOB from "../../assets/image/list-staff.jpg";
import HANNOP from "../../assets/image/hannop.png";
import PROFILE from "../../assets/image/profile.png";
import MONEY from "../../assets/image/money.png";
import "./ListJobOfficial.css";
import ItemJobOfficial from "./ItemJobOfficial";
import { useNavigate } from "react-router-dom";
const data = [
  {
    image: JOB,
    title: "NHÂN VIÊN BÁN HÀNG TẠI SIÊU THỊ",
    location: "Hồ Chí Minh (TP)",
    date: "30/05/2023",
    salary_from: "7.000.000",
    salary_to: "10.000.000",
  },
  {
    image: JOB,
    title: "NHÂN VIÊN BÁN HÀNG TẠI SIÊU THỊ",
    location: "Hồ Chí Minh (TP)",
    date: "30/05/2023",
    salary_from: "7.000.000",
    salary_to: "10.000.000",
  },
  {
    image: JOB,
    title: "NHÂN VIÊN BÁN HÀNG TẠI SIÊU THỊ",
    location: "Hồ Chí Minh (TP)",
    date: "30/05/2023",
    salary_from: "7.000.000",
    salary_to: "10.000.000",
  },
];
export default function ListJobOfficial({ dataOfficial }) {
  console.log(dataOfficial);
  const navigator = useNavigate();
  const handlelist = () => {
    console.log(dataOfficial?.data?.data[0]?.job_type_id);
    navigator("/list-jobs/" + dataOfficial?.data?.data[0]?.job_type_id);
  };
  const handleRangeForm = (job_id) => {
    console.log("hello");
    navigator("/" + job_id);
  };
  return (
    <div className="mt-5">
      <div className="grid grid-cols-2 sm:grid-cols-2 min-[1024px]:grid-cols-4 sm:gap-3 gap-5">
        <div className="col-span-1">
          <img
            src={JOB_OFFICIAL}
            className="hover:cursor-pointer rounded-lg min-h-full"
            onClick={handlelist}
          />
        </div>
        {dataOfficial?.data?.data?.map((res, index) => (
          <ItemJobOfficial item={res} key={index} />
        ))}
      </div>
      <div className=" font-semibold flex justify-center mt-2 text-[14px] text-[#3AA3A4]">
        <div
          className="hover:cursor-pointer  hover:text-[#e97295]"
          onClick={handlelist}
        >
          Xem thêm
        </div>
      </div>
    </div>
  );
}
