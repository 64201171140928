import "./Header.css";
import "../../assets/css/font-text.css"
export default function Header({ title }) {
  console.log(title);
  return (
    <div className="relative">
      <div className="bg-title-right  relative bg-[#46C5C1] w-full max-h-full z-0">
        <div className="font-bold text-header text-white uppercase flex justify-center items-center h-[124px] sm:h-[174px] text-center mx-8">
          {title}
        </div>
        <div className="bg-right z-50"></div>
      </div>
    </div>
  );
}
