import moment from "moment";
import HANNOP from "../../assets/image/hannop.png";
import PROFILE from "../../assets/image/location.png";
import MONEY from "../../assets/image/money.png";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../components/NavSearch/format/format";

export default function ItemJobOfficial({ item }) {
  console.log(item);
  const navigate = useNavigate();

  const handleRangeForm = (job_id) => {
    navigate("/" + job_id);
  };

  function numberWithCommas(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return parts.join(".");
  }
  return (
    <div
      className="border-ani hover:cursor-pointer col-span-1 px-2 rounded-md shadow-grid relative"
      onClick={(e) => handleRangeForm(item.meta_slug)}
    >
      <img
        src={item?.banner_url}
        className="object-cover mt-2 rounded-md"
        alt=""
      />
      <div className="mt-2 ">
        <div className="h-10 sm:h-12 text-[13px] sm:text-[15px] sm:mt-3 md:mt-1 min-[1300px]:mt-0 font-regular uppercase">
          {item?.job_position}
        </div>
        <div>
          <div className="flex items-center w-full mt-2">
            <div>
              <img src={PROFILE} className="w-4 mr-2" />
            </div>
            <div className="text-[12px] sm:text-[14px] font-book text-left">
              {item?.location}
            </div>
          </div>

          <div className="flex items-center w-full mt-2">
            <div>
              <img src={HANNOP} className="w-4 mr-2" />
            </div>
            <div className="text-[12px] sm:text-[14px] font-book text-left">
              Hạn nộp:{" "}
              <span className="text-[10px] sm:text-[14px]">
                {item?.expire_date === "" ? "" : formatDate(item?.expire_date)}
              </span>
            </div>
          </div>

          <div className="flex items-center w-full mt-2">
            <div>
              <img src={MONEY} className="w-4 mr-2" />
            </div>
            <div className="text-[11px] sm:text-[14px] font-book text-left">
              {numberWithCommas(item?.salary_from)} -{" "}
              {numberWithCommas(item?.salary_to)}
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center mb-10 min-[1024px]:mb-16 ">
        <button
          className="absolute bottom-0 btn-ut mb-5 min-[1024px]:mb-8 font-semibold text-[14px] bg-[#3AA3A4] sm:mt-3 min-[1300px]:mt-0 sm:px-8 py-2 px-4 text-white rounded-3xl "
          onClick={() => handleRangeForm(item.meta_slug)}
        >
          Ứng tuyển
        </button>
        <br />
      </div>
    </div>
  );
}
