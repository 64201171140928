import PLACE from "../../assets/image/place.svg";
import MONEY from "../../assets/image/money.svg";
import DEADLINE from "../../assets/image/deadline.svg";
import { useNavigate } from "react-router";
import PRODUCT from "../../assets/image/list-staff.jpg";
import moment from "moment";

export default function ItemListSearch({ data }) {
  console.log(data);
  const nagative = useNavigate();
  const handleRangeForm = (id) => {
    // console.log(id);
    nagative("/" + id);
  };

  const formatDate = (date = "") => {
    return moment(date).format("DD/MM/YYYY");
  };
  function numberWithCommas(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return parts.join(".");
  }
  return (
    <div
      onClick={() => handleRangeForm(data.meta_slug)}
      className="border-ani grid grid-cols-1 sm:grid-cols-2 m-2 list-border mb-7 hover:cursor-pointer"
    >
      <div className="col-span-1 m-2 ">
        <img
          src={data?.banner_url}
          className="sm:w-11/12 w-full lg:max-w-[300px]  rounded-lg"
        />
      </div>
      <div className=" col-span-1">
        <div className="ml-5 sm:ml-0 sm:mt-5">
          <div className="font-regular text-[15px]  uppercase">
            {data?.job_position}
          </div>
          <div className="flex mt-2">
            <img src={PLACE} className="w-3" />
            <div className="ml-2 description-list font-book">
              {data?.location}
            </div>
          </div>
          <div className="flex mt-2">
            <img src={DEADLINE} className="w-3" />
            <div className="ml-2 description-list font-book">
              Hạn nộp:{" "}
              {data?.expire_date === "" ? "" : formatDate(data?.expire_date)}
            </div>
          </div>
          <div className="flex mt-2">
            <img src={MONEY} className="w-3" />
            <div className="ml-2 description-list font-book">
              {numberWithCommas(data.salary_from)} -{" "}
              {numberWithCommas(data.salary_to)}
            </div>
          </div>
        </div>
        <div className="flex mt-3 justify-center md:mt-8 lg:mt-14 sm:justify-end">
          <button
            className="btn-ut bg-[#3AA3A4] text-[15px] px-6 py-2 text-white rounded-3xl"
            onClick={() => handleRangeForm(data.meta_slug)}
          >
            Ứng tuyển
          </button>
        </div>
      </div>
    </div>
  );
}
