import Header from "../component/Header";
import FACE from "../../assets/image/facebook-share.png";
import DETAIL1 from "../../assets/image/detail-1.png";
import DETAIL2 from "../../assets/image/detail-2.png";
import DETAIL3 from "../../assets/image/detail-3.png";
import DETAIL4 from "../../assets/image/detail-4.png";
import WORK from "../../assets/image/work.png";
import BD from "../../assets/image/bd.png";
import TT from "../../assets/image/tt.png";
import NEW1 from "../../assets/image/news-1.png";
import NEW2 from "../../assets/image/news-2.png";

import Footer from "../component/Footer/Footer";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ToggleSmooth from "../../components/NavSearch/togglesmooth/togglesmooth";

const title = "MVCPRO TỔ CHỨC CHƯƠNG TRÌNH HUẤN LUYỆN CHO NHÂN VIÊN BÁN HÀNG";

export default function NewDetails() {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleNewDetail = () => {
    window.scrollTo(0, 0);
    navigate("/news-detail");
  };
  return (
    <div>
      <div className="lg:mt-20 mt-14">
        <Header title={title} />
      </div>
      <div className="w-[90%] sm:w-[65%] min-[1024px]:w-[90%] min-[1700px]:w-[55%] min-[1300px]:w-[73%] relative content-main">
        <div className="mt-6">
          <div className="flex justify-between">
            <div className="text-gray-500 font-book text-[12px]">
              Thứ hai, 4 tháng 7, 2022
            </div>
            <div>
              <div className="w-20">
                <a
                  href="https://www.facebook.com/share.php?u=https://www.facebook.com/mvcpro.vn"
                  target="_blank"
                >
                  <img src={FACE} className="w-20 cursor-pointer" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5 p-3 text-[13px] rounded-lg font-book shadow-grid">
          Không phân biệt cấp bậc giữa lãnh đạo và nhân viên, không có khoảng
          cách giữa địa phương hay vùng miền, hơn 500 anh em nhân viên khối Siêu
          thị trên toàn quốc hội tụ và cùng tham gia chuyến đi team building.
          Kết nối để kiến tạo sức mạnh toàn năng - Làm hết sức và chơi hết mình.
          Đó là tinh thần mà đại gia đình MVCPRO luôn theo đuổi!
        </div>
        <div className="mt-10">
          <div>
            <img src={DETAIL1} className="w-full" />
          </div>
          <div>
            <img className="mt-2 w-full" src={DETAIL2} />
          </div>
          <div>
            <img className="mt-2 w-full" src={DETAIL3} />
          </div>
          <div>
            <img className="mt-2 w-full" src={DETAIL4} />
          </div>
        </div>

        <div className="flex mt-10">
          <div className="mr-2 flex items-center">
            <img src={WORK} className="w-5" />
          </div>
          <div className="font-regular text-[#3AA3A4] text-[24px]">
            BÀI VIẾT CÙNG CHUYÊN MỤC
          </div>
        </div>

        <div className="my-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          <div
            className="col-span-1 p-2 shadow-grid rounded-lg hover:cursor-pointer"
            onClick={handleNewDetail}
          >
            <img
              src={TT}
              className="rounded-md repon-img w-full max-h-[194px]"
            />
            <div className="text-[16px] text-reponsive mt-2 font-regular">
              MVCPRO tổ chức chương trình huấn luyện cho Nhân viên bán hàng
            </div>
            <p className="text-[14px] text-reponsive mt-2 font-book">
              Những Nữ chiến binh bán hàng của MVCPRO đã có những trải nghiệm
              tuyệt vời tại buổi Training Kỹ Năng đọc vị tâm lý khách hàng và
              chốt sale...
            </p>
            <div className="hover:cursor-pointer text-[13px] mt-2 font-book text-[#3AA3A4]">
              Xem thêm {">"}
            </div>
          </div>

          <div
            className="col-span-1 p-2 shadow-grid rounded-lg hover:cursor-pointer"
            onClick={handleNewDetail}
          >
            <img
              src={NEW1}
              className="rounded-md w-full repon-img max-h-[194px]"
            />
            <div className="text-[15px] text-reponsive mt-2 font-regular">
              MVCPRO tổ chức chương trình huấn luyện cho Nhân viên bán hàng
            </div>
            <p className="text-[14px] text-reponsive mt-2 font-book">
              Những Nữ chiến binh bán hàng của MVCPRO đã có những trải nghiệm
              tuyệt vời tại buổi Training Kỹ Năng đọc vị tâm lý khách hàng và
              chốt sale...
            </p>
            <div className="hover:cursor-pointer text-[13px] mt-2 font-book text-[#3AA3A4]">
              Xem thêm {">"}
            </div>
          </div>
          <div
            className="col-span-1 p-2 shadow-grid rounded-lg hover:cursor-pointer"
            onClick={handleNewDetail}
          >
            <img
              src={NEW2}
              className="rounded-md w-full repon-img max-h-[194px]"
            />
            <div className="text-[15px] text-reponsive mt-2 font-regular">
              MVCPRO tổ chức chương trình huấn luyện cho Nhân viên bán hàng
            </div>
            <p className="text-[14px] text-reponsive mt-2 font-book">
              Những Nữ chiến binh bán hàng của MVCPRO đã có những trải nghiệm
              tuyệt vời tại buổi Training Kỹ Năng đọc vị tâm lý khách hàng và
              chốt sale...
            </p>
            <div className="hover:cursor-pointer text-[13px] mt-2 font-book text-[#3AA3A4]">
              Xem thêm {">"}
            </div>
          </div>
        </div>
      </div>
      <div className="bg-footer mt-16">
        <Footer />
      </div>
      <ToggleSmooth />
    </div>
  );
}
