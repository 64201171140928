import "./App.css";
import FormRegister from "./page/FormRegister/FormRegister";
import ContentRecruitment from "./page/ContentRecruitment/ContentRecruitment";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./page/component/Header/HeaderNavBar";
import PageMain from "./page/Main/PageMain";
import { QueryClient, QueryClientProvider } from "react-query";
import PageSearch from "./page/Main/PageSearch";
import ListJob from "./page/ListJob/ListJob";
import News from "./page/News/News";
import Contact from "./page/Contact/Contact";
import Tracuu from "./page/Tracuu/Tracuu";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NewDetails from "./page/NewDetails/NewDetails";
function App() {
  const queryClient = new QueryClient();
  return (
    <div className="">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <Routes>
            <Route
              path="/"
              element={
                <div>
                  <PageMain />
                </div>
              }
            />
            <Route
              path="/search"
              element={
                <div>
                  <PageSearch />
                </div>
              }
            />
            <Route
              path="/news-detail"
              element={
                <div>
                  <Header />

                  <NewDetails />
                </div>
              }
            />
            <Route
              path="/list-jobs/:id"
              element={
                <div>
                  <ListJob />
                </div>
              }
            />
            <Route
              path="/:id"
              element={
                <div>
                  <Header />
                  <ContentRecruitment />
                </div>
              }
            />
            <Route
              path="/tracuu"
              element={
                <div>
                  <Header />
                  <Tracuu />
                </div>
              }
            />
            <Route
              path="/contact"
              element={
                <div>
                  <Header />
                  <Contact />
                </div>
              }
            />
            <Route
              path="/second"
              element={
                <div>
                  <Header />
                  <ContentRecruitment />
                </div>
              }
            />
            <Route
              path="/third"
              element={
                <div>
                  <Header />
                  <News />
                </div>
              }
            />

            <Route
              path="/four"
              element={
                <div>
                  <Header />
                  <ContentRecruitment />
                </div>
              }
            />

            <Route
              path="/form-register/:id"
              element={
                <div>
                  <Header />
                  <FormRegister />
                </div>
              }
            />
          </Routes>
        </QueryClientProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
