import PLACE from "../../assets/image/place.svg";
import LOCATION from "../../assets/image/location.svg";
import AMOUNT from "../../assets/image/amount.svg";
import GENDER from "../../assets/image/gender.svg";
import TYPE_JOB from "../../assets/image/typejob.svg";
import EXP from "../../assets/image/exp.svg";
import DEADLINE from "../../assets/image/deadline.svg";
import { useNavigate } from "react-router-dom";
import moment from "moment";

export default function PopupContent({ jobDetail }) {
  console.log(jobDetail);
  const navigate = useNavigate();
  const handleChangeRegister = (job_id) => {
    navigate("/form-register/" + job_id);
  };
  const formatDate = (date = "") => {
    return moment(date).format("DD/MM/YYYY");
  };
  return (
    <div className="col-span-1 sm:col-span-2 mx-5">
      <div className="categ-place">
        <div className="flex items-center">
          <div className="bor-img flex justify-center items-center">
            <img src={PLACE} className="w-5" />
          </div>
          <div className="ml-4 ">
            <div className="font-title-place font-book">Nơi làm việc</div>
            <div className="font-gothamRegular font-place  font-regular">
              {jobDetail?.data?.location}
            </div>
          </div>
        </div>
        <div className="flex items-center mt-4">
          <div className="bor-img flex justify-center items-center">
            <img src={LOCATION} className="w-5" />
          </div>
          <div className="ml-4 ">
            <div className="font-title-place font-book">Địa điểm làm việc</div>
            <div className="font-gothamRegular font-place  font-regular">
              {jobDetail?.data?.store}
            </div>
          </div>
        </div>
        <div className="flex items-center mt-4">
          <div className="bor-img flex justify-center items-center">
            <img src={AMOUNT} className="w-5" />
          </div>
          <div className="ml-4 ">
            <div className="font-title-place font-book">
              Số lượng tuyển dụng
            </div>
            <div className="font-gothamRegular font-place  font-regular">
              {jobDetail?.data?.target}
            </div>
          </div>
        </div>
        <div className="flex items-center mt-4">
          <div className="bor-img flex justify-center items-center">
            <img src={GENDER} className="w-5" />
          </div>
          <div className="ml-4 ">
            <div className="font-title-place font-book">Giới tính</div>
            <div className="font-gothamRegular font-place  font-regular">
              {jobDetail?.data?.gender === "male"
                ? "Nam"
                : jobDetail?.data?.gender === "no"
                ? "Không yêu cầu Nam và Nữ"
                : "Nữ"}
            </div>
          </div>
        </div>
        <div className="flex items-center mt-4">
          <div className="bor-img flex justify-center items-center">
            <img src={TYPE_JOB} className="w-5" />
          </div>
          <div className="ml-4 ">
            <div className="font-title-place font-book">Loại công việc</div>
            <div className="font-gothamRegular font-place  font-regular">
              {jobDetail?.data?.job_type}
            </div>
          </div>
        </div>
        <div className="flex items-center mt-4">
          <div className="bor-img flex justify-center items-center">
            <img src={EXP} className="w-5" />
          </div>
          <div className="ml-4 ">
            <div className="font-title-place font-book">
              Trình độ / Kinh nghiệm
            </div>
            <div className="font-gothamRegular font-place  font-regular ">
              {jobDetail?.data?.degree_id}
            </div>
          </div>
        </div>
        <div className="flex items-center mt-4">
          <div className="bor-img flex justify-center items-center">
            <img src={DEADLINE} className="w-5" />
          </div>
          <div className="ml-4 ">
            <div className="font-title-place font-book">Hạn nộp hồ sơ</div>
            <div className="font-gothamRegular font-place  font-regular">
              {jobDetail?.data?.expire_date === ""
                ? ""
                : formatDate(jobDetail?.data?.expire_date)}
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-5">
          <button
            className="btn-ut bg-[#3AA3A4] text-[13px] h-8 px-4 text-white rounded-2xl"
            onClick={() => handleChangeRegister(jobDetail?.data?.meta_slug)}
          >
            Ứng tuyển ngay
          </button>
        </div>
      </div>
    </div>
  );
}
